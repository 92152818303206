<template>
  <div>
    <BaseHeader />
    <div class="container-fluid details">
      <Carousel :images="lstingDetails[0].img" />
      <div class="row">
        <div class="col-lg-9">
          <!-- <Breadcrumb /> -->
          <div class="details-title">
            <h1 v-html="lstingDetails[0].title"></h1>
          </div>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <img class="details-icon" src="/static/CardCategory.png" />
              <span class="details-text">Restaurant</span>
            </div>
          </div>
          <div class="listing-tags">
            <!-- <span class="listing-tag">Pakistani</span>
          <span class="listing-tag">BBQ</span>
          <span class="listing-tag">Dine-In</span>
          <span class="listing-tag">Take Away</span> -->
            <spn
              class="listing-tag"
              v-for="(catlist, index) in stringToArray(
                lstingDetails[0].categories
              )"
              :key="index"
            >
              {{ catlist }}
            </spn>
          </div>

          <div class="star-rating">
            <p>{{ lstingDetails[0].rating }}</p>
            <!-- <img src="../static/Fullstar.png" alt="" /> -->
            <p>({{ lstingDetails[0].review_count }})</p>
          </div>

          <div class="description">
            <p v-html="lstingDetails[0].content"></p>

            <!-- <p><u style="color: #ff1613; font-weight: 500">See More</u></p> -->
          </div>

          <!-- <ReviewComment />
        <ReviewForm /> -->
          <!-- <ReviewRating /> -->
        </div>

        <div class="col-lg-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 col-md-12 col-sm-12">
                <div class="rating">
                  <p>
                    {{ lstingDetails[0].rating }} / 5<span>
                      reviews({{ lstingDetails[0].review_count }})</span
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
          <!-- <DetailRightSidebar /> -->
          <ShowAllTimings
            :timings="lstingDetails[0].old_timings"
            v-if="lstingDetails[0].old_timings"
          />
          <DetailsMap
            :address="lstingDetails[0].address"
            :phone="lstingDetails[0].phone"
            :web="lstingDetails[0].web"
          />
          <AdditionalDetails
            :drive_thru="lstingDetails[0].drive_thru"
            :take_out="lstingDetails[0].take_out"
            :free_wifi="lstingDetails[0].free_wifi"
            :dine_in="lstingDetails[0].dine_in"
            :wheelchair_access="lstingDetails[0].wheelchair_access"
          />
          <!-- <ClaimReport /> -->
        </div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import Carousel from '@/components/Listing/Carousel.vue';
import ShowAllTimings from '@/components/Listing/ShowAllTimings.vue';
import DetailsMap from '@/components/Listing/DetailsMap.vue';
import AdditionalDetails from '@/components/Listing/AdditionalDetails.vue';

export default {
  name: 'ListingDetails',
  components: {
    BaseHeader,
    BaseFooter,
    Carousel,
    ShowAllTimings,
    DetailsMap,
    AdditionalDetails,
  },
  data() {
    return {
      listingSlug: this.$route.params.slug,
      lstingDetails: [{ id: 1, title: 'My journey with Vue' }],
    };
  },
  mounted() {
    this.getListingDetails();
  },
  methods: {
    stringToArray(string) {
      console.log(typeof string);
      if (typeof string == 'string') {
        return string.split(',');
      } else {
        return string;
      }
    },
    async getListingDetails() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(
          this.apiUrl + 'listingdetails/' + this.listingSlug,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.lstingDetails = data;
        //console.log(data['data'], 'zain');
        const loadingOverlay = document.getElementById('loading');
        loadingOverlay.style.display = 'none';
        this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}
*::-webkit-scrollbar-track {
  background: #1e1e24;
}
*::-webkit-scrollbar-thumb {
  background: -webkit-radial-gradient(
    circle,
    #ff1613 0%,
    #dc1e1e 50%,
    #dc1e1ecc 100%
  );
  border-radius: 10px;
}
.rating {
  background-color: #000;
  border: 1px solid #fff;

  p {
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    padding: 6px 0;
    margin: 0;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
  }
}
.description {
  p {
    a {
      color: #ff1613 !important;
    }
  }
}
</style>
<style scoped lang="scss">
.details {
  background-color: #000;
  width: 100%;

  .row {
    margin-top: 60px;
  }

  .details-text,
  .details-title,
  .details-icon {
    margin-left: 10px;
  }
}
.listing-tags {
  margin-top: 40px;
  margin-bottom: 20px;
  margin-left: 10px;
}

.listing-tag {
  margin-right: 10px;
  padding: 5px;
  border: solid 1px #ff1613;
  border-radius: 10px;
  font-size: 12px;
}
.star-rating {
  display: flex;
  width: 15px;
  height: 25px;
  margin-left: 10px;
  p {
    font-size: 18px;
  }
}
.description {
  margin-top: 20px;
  margin-left: 10px;
  margin-bottom: 30px;
}
</style>
