import { createApp } from 'vue';
import App from './App.vue';
import router from './router';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap';

// createApp(App).use(router).mount('#app');
let app = createApp(App);
app.config.globalProperties.apiUrl =
  'https://planetautopart.com/server/app/api/';
app.use(router).mount('#app');
