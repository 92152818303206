import { ShowAllTimings } from '#build/components';
<template>
  <div class="container-fluid">
    <div class="col-lg-12 col-md-12">
      <div class="accordion" id="accodionExample">
        <div class="accordion-item">
          <h2 class="accordion-header" id="headingOne">
            <button
              class="accordion-button text-danger pl-2"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#collapse"
              aria-expanded="true"
              aria-controls="collapse"
            >
              <img src="@/assets/Time.png" />
              Timings
            </button>
          </h2>
          <div
            id="collapse"
            class="accordion-collapse collapse show"
            aria-labelledby="headingOne"
            data-bs-parent="#accodionExample"
          >
            <div class="accordion-body">
              <strong>{{ timings }} </strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ListingTimings',
  props: {
    timings: String,
  },
};
</script>
<style scoped lang="scss">
.accordion {
  margin-top: 20px;

  .accordion-item {
    background-color: #000;
    border-radius: 0;
    margin-top: 10px;
  }

  .accordion-body {
    color: #fff;
    // letter-spacing: 1px;
    font-weight: 300;
  }

  .accordion-button {
    background-color: #fff;
  }

  img {
    margin-right: 10px;
  }
}
</style>
