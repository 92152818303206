<template>
  <div>
    <BaseHeader />
    <div class="homeSlider">
      &nbsp;
      <div class="col-lg-12">
        <div class="home-text onMobile">
          <h1 class="desktopOnly">Contact Us</h1>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="container">
        <div class="row main-row">
          <div class="col-lg-6 c-info">
            <h4>REACH US DIRECTLY</h4>
            <div class="row c-row contact_details">
              <div class="col-1">
                <img src="@/assets/location2.png" class="c-icom" />
              </div>
              <div class="col-11 address_text">
                101 Freshway Dr unit# 46, Concord, ON L4K 1R9
              </div>
            </div>
            <div class="row c-row">
              <div class="col-1">
                <img src="@/assets/phone-call.png" class="c-icom" />
              </div>
              <div class="col-11 address_text">+1 416-878-0998</div>
            </div>
            <div class="row c-row">
              <div class="col-1">
                <img src="@/assets/email.png" class="c-icom" />
              </div>
              <div class="col-11 address_text">
                myhalalrestaurants@gmail.com
              </div>
            </div>
          </div>
          <div class="col-lg-6 c-form">
            <div class="row" v-if="!contactFormCheck">
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="name"
                    class="form-control"
                    placeholder="Your Name"
                  />
                  <small v-if="nameError != ''" class="form-text small_red"
                    >Name is required.</small
                  >
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="email"
                    class="form-control"
                    placeholder="Your Email"
                  />
                  <small
                    v-if="emailError != ''"
                    v-html="emailError"
                    class="form-text small_red"
                  ></small>
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <input
                    type="text"
                    v-model="subject"
                    class="form-control"
                    placeholder="Subject"
                  />
                  <small v-if="subjectError != ''" class="form-text small_red"
                    >Subject is required.</small
                  >
                </div>
              </div>
              <div class="col-lg-12">
                <div class="form-group">
                  <textarea
                    class="form-control"
                    v-model="message"
                    placeholder="Your Messages"
                    rows="2"
                  ></textarea>
                </div>
              </div>
              <div class="col-lg-12">
                <button
                  type="button"
                  @click="submitContactUs"
                  class="btn btn-outline-danger"
                >
                  Submit
                </button>
              </div>
            </div>
            <div class="row cf-success-outer" v-if="contactFormCheck">
              <div class="col-12 cf-success">
                {{ successMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46082.923884002616!2d-79.54920596274424!3d43.789820943585596!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b2e4c98fff463%3A0xb5afc13f0f044e14!2s101%20Freshway%20Dr%20unit%23%2046%2C%20Concord%2C%20ON%20L4K%201R9%2C%20Canada!5e0!3m2!1sen!2s!4v1728927185126!5m2!1sen!2s"
        width="100%"
        height="300"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';

export default {
  name: 'ContactUs',
  components: {
    BaseHeader,
    BaseFooter,
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      nameError: '',
      emailError: '',
      subjectError: '',
      contactFormCheck: false,
      successMessage: 'Thanks',
    };
  },
  mounted() {},
  methods: {
    submitContactUs() {
      if (this.name == '') {
        this.nameError = 'Name is required.';
      } else {
        this.nameError = '';
      }
      if (this.email == '') {
        this.emailError = 'Email is required.';
      } else {
        // this.emailError = '';
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (regex.test(this.email)) {
          this.emailError = '';
        } else {
          //alert('Please enter valid email.');
          this.emailError = 'Please enter valid email.';
        }
      }
      if (this.subject == '') {
        this.subjectError = 'Subject is required.';
      } else {
        this.subjectError = '';
      }
      if (this.name != '' && this.email != '' && this.subject != '') {
        this.getLatestListings();
      }
    },
    async getLatestListings() {
      try {
        const response = await fetch(this.apiUrl + 'contact_form', {
          method: 'POST',
          // headers: {
          //   // 'Content-Type': 'application/json',
          //   name: this.name,
          //   email: this.email,
          //   subject: this.subject,
          //   message: this.message,
          // },
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          }),
        });
        //const response = await fetch('/api/citylist?name=' + this.cityDDModel);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data['results']['affectedRows']);
        if (data['results']['affectedRows'] == 1) {
          this.successMessage =
            'Thank you for contacting us. We will back to you soon!';
          this.contactFormCheck = true;
        }
        // console.log(data, 'zain');
        // document.getElementById('searchCityDiv').style.display = 'block';
        // this.citiesff = data;
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.cf-success-outer {
  box-sizing: border-box;
  padding-left: 20px;
  padding-right: 20px;
}
.contact_details {
  margin-top: 40px !important;
}
.address_text {
  font-weight: 300 !important;
  line-height: 40px !important;
}
.cf-success {
  font-weight: 300;
  color: green;
  font-size: 15px;
  margin-top: 20px;
  border: 1px solid greenyellow;
  padding: 15px;
  background-color: #adff2f25;
  text-align: center;
  height: 150px;
  line-height: 120px;
}

.small_red {
  color: red !important;
  font-size: 10px !important;
  padding-left: 10px;
  margin-top: -3px !important;
  display: block;
  margin-bottom: 5px !important;
  font-weight: 300 !important;
}
.btn-outline-danger {
  width: 100%;
  margin-top: 10px;
}
.c-form {
  background-color: white;
  padding: 30px;
}
.main-row {
  padding-top: 30px;
  padding-bottom: 30px;
}
.c-row {
  margin-top: 20px;
}
.c-icom {
  width: 30px;
}
.c-info {
  padding: 30px;
  background-image: url('@/assets/contact2.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
}
.homeSlider {
  background-image: url('@/assets/contact.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 260px;
}
.about {
  background-color: black;
  .about-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    table {
      width: 600px;
      margin: 0 auto;
      tr td {
        padding: 10px;
      }
      p {
        margin-left: 20px;
        font-size: 16px;
      }
    }
    .count {
      width: 100px;
    }
    .centerAlign {
      text-align: center;
      font-weight: 300;
      font-size: 24px;
    }
    h4 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-left: 20px;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      font-style: normal;
      a {
        color: #dc3545;
        font-weight: 500;
      }
    }
    .intro {
      text-align: center;
    }
  }
}
.home-text {
  text-align: center;
  margin-top: 100px;
}

.home-text h1 {
  font-size: 45px;
  color: #fff;
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666,
    0 5px 0 #555, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  .homeSlider {
    height: 600px;
  }

  .home-text h1 {
    font-size: 70px;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .home-text h1 {
    font-size: 70px;
  }
}
</style>
<style scoped>
textarea {
  margin-top: 15px !important;
}
input {
  margin-top: 15px !important;
}
</style>
