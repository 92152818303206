// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '@/views/HomeView.vue';
import HowItWorks from '@/views/how-it-works.vue';
import ContactUs from '@/views/contact-us.vue';
import LC from '@/views/listing-category.vue';
import LCC from '@/views/listing-city-category.vue';
import Listing from '@/views/listing.vue';
import Post from '@/views/PostDetail.vue';
import AboutUs from '@/views/AboutUs.vue';
import TipsArticles from '@/views/TipsArticles.vue';
import TC from '@/views/TechC.vue';
import BS from '@/views/BrandingService.vue';
import UiUx from '@/views/UiUx.vue';
import VideoEditing from '@/views/VideoEditing.vue';
import ThreeD from '@/views/ThreeD.vue';
import AnnimationServices from '@/views/AnnimationServices.vue';

const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
  },
  {
    path: '/how-it-works',
    name: 'HowItWorks',
    component: HowItWorks,
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: ContactUs,
  },
  {
    path: '/listing-category/:slug',
    name: 'LC',
    component: LC,
  },
  {
    path: '/listing-city-category/:slug/:city',
    name: 'LCC',
    component: LCC,
  },
  {
    path: '/listing/:slug',
    name: 'Listing',
    component: Listing,
  },
  {
    path: '/:slug',
    name: 'Post',
    component: Post,
  },
  {
    path: '/about-us',
    name: 'AboutUs',
    component: AboutUs,
  },
  {
    path: '/tips-articles',
    name: 'TipsArticles',
    component: TipsArticles,
  },
  {
    path: '/technology-consultancy',
    name: 'TC',
    component: TC,
  },
  {
    path: '/branding-service',
    name: 'BS',
    component: BS,
  },
  {
    path: '/uiux',
    name: 'UIUX',
    component: UiUx,
  },
  {
    path: '/VideoEditing',
    name: 'VideoEditing',
    component: VideoEditing,
  },
  {
    path: '/ThreeD',
    name: 'ThreeD',
    component: ThreeD,
  },
  {
    path: '/AnnimationServices',
    name: 'AnnimationServices',
    component: AnnimationServices,
  },
];

const router = createRouter({ history: createWebHistory(), routes });
export default router;
