<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 bg-dark">
        <div class="categorycards">
          <img src="@/assets/Detailmap.png" alt="shisha" />
          <div class="card-body">
            <h4 class="card-title">
              <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <img src="@/assets/map.png" style="width: 20px" alt="" />
                </div>
                <span class="details-location">{{ address }}</span>
              </div>
            </h4>
            <p class="card-text"></p>
          </div>
          <div class="details-tags">
            <!-- <span class="details-tag">Get Direction</span> -->
          </div>
          <hr />
          <div class="call">
            <img src="@/assets/Call.png" alt="contact number" /><span>{{
              phone
            }}</span>
          </div>
          <hr />
          <div class="website">
            <img src="@/assets/Website.png" alt="website" /><span>{{
              web
            }}</span>
          </div>
          <!-- <hr>
                    <div class="social-media">
                        <nuxt-link to="https:www.instagram.com/myhalalrestaurants/">
                            <img src="../static/Winstagram.png" alt="instagram">
                        </nuxt-link>
                        <nuxt-link to="https:www.youtube.com/@myhalalrestaurants/">
                        <img src="../static/Wyoutube.png" alt="youtube">
                    </nuxt-link>
                    <nuxt-link to="https:www.facebook.com/myhalalrestaurants/">
                        <img src="../static/Wfacebook.png" alt="facebook">
                    </nuxt-link>
                    <nuxt-link to="https:www.twitter.com/myhalalrestaurants/">
                        <img src="../static/Wtwitter.png" alt="twitter">
                    </nuxt-link>
                    <nuxt-link to="https:www.tiktok.com/@myhalalrestaurants/">
                        <img src="../static/Wtiktok.png" alt="tiktok">
                    </nuxt-link>
                    </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DetailsMap',
  props: {
    address: String,
    phone: Number,
    web: String,
  },
};
</script>

<style scoped lang="scss">
.categorycards {
  width: 100%;
  margin-top: 30px;
  background-color: rgba(72, 72, 72, 0.5);
  padding: 10px;
  height: 0 auto;
  border: 1px solid #fff;

  img {
    width: 100%;
  }

  .details-location {
    padding-left: 5px;
    margin-top: 10px;
    font-size: 10px;
    font-weight: 300;
  }

  .details-tags {
    // margin-top: -20px;
    margin-bottom: 20px;
    padding-bottom: 10px;
  }

  .details-tag {
    padding: 5px;
    border: solid 1px #ff1613;
    border-radius: 10px;
    font-size: 10px;
  }

  .call,
  .website {
    padding-top: 10px;
    padding-bottom: 10px;

    // justify-content: center;
    // align-items: center;
    img {
      width: 20px;
    }

    span {
      font-size: 13px;
      // margin-top: -20px;
      margin-left: 5px;
    }
  }

  .social-media {
    padding-top: 10px;
    padding-bottom: 10px;
    // text-align: center;
    display: flex;
    justify-content: space-between;

    img {
      width: 28px;
    }
  }
}
</style>
