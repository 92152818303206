<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12 col-md-12 bg-dark">
        <div class="categorycards">
          <div class="card-body">
            <h4 class="card-title"></h4>
            <p class="card-text">Additional Details</p>
          </div>
          <hr />
          <span v-if="drive_thru == 1">Drive Thru</span>
          <hr v-if="drive_thru == 1" />
          <span v-if="take_out == 1">Take-out</span>
          <hr v-if="take_out == 1" />
          <span v-if="free_wifi == 1">Free Wifi</span>
          <hr v-if="free_wifi == 1" />
          <span v-if="dine_in == 1">Dine-in</span>
          <hr v-if="dine_in == 1" />
          <span v-if="wheelchair_access == 1">Wheelchair Accessible</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AdditionalDetails',
  props: {
    drive_thru: Number,
    take_out: Number,
    free_wifi: Number,
    dine_in: Number,
    wheelchair_access: Number,
  },
};
</script>
<style scoped lang="scss">
.categorycards {
  width: 100%;
  margin-top: 30px;
  background-color: rgba(72, 72, 72, 0.5);
  padding: 10px;
  height: 0 auto;
  border: 1px solid #fff;

  img {
    width: 100%;
  }
  .card-text {
    font-weight: 500;
  }
}
</style>
