<template>
  <div class="blogssection">
    <div class="blogstext">
      <h2>Explore What's Trending</h2>
      <p>Browse the latest articles from our blog</p>
    </div>

    <div class="container">
      <div class="row">
        <div
          class="col-lg-3 col-md-6"
          v-for="(post, index) in postList"
          :key="index"
        >
          <router-link :to="'/' + post.slug + '/'" class="nav-link">
            <div class="blogscards">
              <img :src="post.img" alt="shisha" />
              <div class="blogs-tags">
                <span class="blogs-tag">{{ post.focus_keyword }}</span>
              </div>
              <div class="card-body">
                <h4 class="card-title">
                  {{ post.title }}
                </h4>
                <p class="card-text"></p>
              </div>
            </div>
          </router-link>
        </div>

        <!-- <div class="col-lg-3 col-md-6 bg-dark">
          <div class="blogscards">
            <img src="../static/Biryani.png" alt="biryani" />
            <div class="blogs-tags">
              <span class="blogs-tag">Halal Food</span>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                What is Halal Food – A Comprehensive Answery
              </h4>
              <p class="card-text"></p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 bg-dark">
          <div class="blogscards">
            <img src="../static/Dryfruit.png" alt="dryfruit" />
            <div class="blogs-tags">
              <span class="blogs-tag">Dry Fruits</span>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                Are There Any Physical Health Benefits of Fasting in Ramadan?
              </h4>
              <p class="card-text"></p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6 bg-dark">
          <div class="blogscards">
            <img src="../static/Meat.png" alt="meat" />
            <div class="blogs-tags">
              <span class="blogs-tag">Ramadan Kareem</span>
            </div>
            <div class="card-body">
              <h4 class="card-title">
                All About Ramadan Halal Meat Market 2024
              </h4>
              <p class="card-text"></p>
            </div>
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      postList: [{ id: 1, title: 'My journey with Vue' }],
    };
  },
  mounted() {
    this.getCategoryList();
  },
  methods: {
    async getCategoryList() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(this.apiUrl + 'getlatestposts', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.postList = data;
        const loadingOverlay = document.getElementById('loading');
        loadingOverlay.style.display = 'none';
        //console.log(data['data'], 'zain');
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.blogssection {
  background: url(../../assets/blogsbackground.png);
  background-color: #000;
  height: auto;
  padding-bottom: 60px;
  padding-top: 60px;

  .blogstext {
    h2,
    p {
      color: #ff1613;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      font-style: medium;
      text-align: center;
    }

    p {
      color: #fff;
      font-weight: 200;
    }
  }
  .blogscards:hover {
    box-shadow: 4px 8px 40px #919191ff;
  }
  .blogscards {
    border: 1px solid #fff;
    position: relative;
    background: rgba(72, 72, 72, 0.8);
    border-radius: 10px;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    box-shadow: 1px 2px 10px #919191b2;

    img {
      width: 100%;
      object-fit: cover;
      margin-bottom: 20px;
      border-radius: 10px 10px 0 0;
    }
  }

  .blogs-tag {
    padding: 5px 10px 5px 10px;
    border: solid 1px #ff1613;
    border-radius: 10px;
    font-size: 12px;
    margin-left: 10px;
  }

  .card-body {
    .card-title {
      margin-top: 20px;
      font-size: 16px;
      margin-left: 10px;
      margin-right: 10px;
      padding-bottom: 20px;
      font-weight: 300;
      min-height: 60px;
    }
  }
}
</style>
