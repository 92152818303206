<template>
  <div class="bg-black">
    <BaseHeader />
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6">
          <!-- <Filters /> -->
          <CategoryCards />
        </div>
        <div class="col-lg-6">
          <CategoryMap />
        </div>
      </div>
    </div>
    {{ slug }}
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';
import CategoryCards from '@/components/Map/CategoryCards.vue';
import CategoryMap from '@/components/Map/CategoryMap.vue';

export default {
  name: 'ContactUs',
  props: ['slug'],
  components: {
    BaseHeader,
    BaseFooter,
    CategoryCards,
    CategoryMap,
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      nameError: '',
      emailError: '',
      subjectError: '',
      contactFormCheck: false,
      successMessage: 'Thanks',
    };
  },
  mounted() {
    //alert(this.$route.params.slug);
  },
  methods: {},
};
</script>
<style lang="scss">
.bg-black {
  background-color: #000;
}
</style>
