<template>
  <!--Carousel Start-->
  <div class="slide_container_outer">
    <div class="slide_container" :style="{ width: `${sliderWidth}px` }">
      <div
        class="slide_inner"
        :style="{ backgroundImage: `url(${catlist})` }"
        v-for="(catlist, index) in stringToArray(images)"
        :key="index"
      >
        <!-- <div class="slid_image_container">
          <img :src="catlist" class="d-block w-20" alt="..." />
        </div> -->
        <!-- {{ catlist }} -->
      </div>
      <!-- <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../static/image1.png" class="d-block w-20" alt="..." />
        </div>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../static/image2.png" class="d-block w-20" alt="..." />
        </div>
      </div>
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../static/image3.png" class="d-block w-20" alt="..." />
        </div>
      </div> -->
      <!-- <button
        class="carousel-control-prev"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="prev"
      >
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Previous</span>
      </button>
      <button
        class="carousel-control-next"
        type="button"
        data-bs-target="#carouselExampleControls"
        data-bs-slide="next"
      >
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden">Next</span>
      </button> -->
    </div>
  </div>
  <!--Carousel End-->
</template>
<script>
export default {
  name: 'ListingCarousel',
  data() {
    return {
      totalImages: 0,
      sliderWidth: 0,
    };
  },
  props: {
    images: String,
  },
  mounted() {
    // console.log(this.images, 'zzzz');
  },
  methods: {
    stringToArray(string) {
      //console.log(typeof string);
      let imagesArray = null;
      if (typeof string == 'string') {
        imagesArray = string.split(',');
        this.totalImages = imagesArray.length;
        this.sliderWidth = this.totalImages * 400;
        return imagesArray;
      } else {
        return string;
      }
    },
  },
};
</script>
<style scoped>
.slide_container_outer {
  overflow: scroll;
  overflow-y: hidden;
  height: 300px;
}
.slide_container {
  height: 200px;
  display: block;
  float: left;
}
.slid_image_container img {
  float: left;
  height: 300px;
  display: block;
}
.slid_image_container {
  display: block;
  float: left;
  height: 300px;
}
.slide_inner {
  display: block;
  height: 300px;
  width: 400px;
  float: left;
  background-position: center;
  background-size: cover;
}
.carousel {
  display: flex;
}
*::-webkit-scrollbar {
  width: 0.2rem;
  height: 10px;
}
*::-webkit-scrollbar-track {
  background: #1e1e24;
}
*::-webkit-scrollbar-thumb {
  background: -webkit-radial-gradient(
    circle,
    #ff1613 0%,
    #dc1e1e 50%,
    #dc1e1ecc 100%
  );
  border-radius: 10px;
  width: 5px;
  height: 5px;
}
</style>
