<template>
  <footer class="bg-slate-900">
    <div class="p-3 flex justify-center text-xs">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <img src="@/assets/footerLogo.png" alt="footerlogo" />
            <div class="card-body">
              <p class="card-text">
                Find great Halal places to eat and shop in Canada!
              </p>
              <div class="footer-socialmedia">
                <router-link to="https://www.youtube.com/@myhalalrestaurants"
                  ><img src="../assets/Youtube.png" alt="youtube"
                /></router-link>
                <router-link to="https://www.instagram.com/myhalalrestaurants/"
                  ><img src="../assets/Instagram.png" alt="instagram"
                /></router-link>
                <router-link to="https://www.facebook.com/MyHalalRestaurants/"
                  ><img src="../assets/Facebook.png" alt="facebook"
                /></router-link>
                <router-link to="https://www.tiktok.com/@myhalalrestaurants"
                  ><img src="../assets/Tiktok.png" alt="tiktok"
                /></router-link>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-md-6">
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="footercards">
                  <h4 class="card-title">Categories</h4>
                  <div class="card-body">
                    <p class="card-text">
                      <router-link
                        to="/listing-category/desserts/"
                        class="nav-link"
                        >Desserts</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-category/bakery/"
                        class="nav-link"
                        >Bakery</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-category/catering/"
                        class="nav-link"
                        >Catering</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-category/shisha/"
                        class="nav-link"
                        >Shisha</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="footercards">
                  <h4 class="card-title">Top Cities</h4>
                  <div class="card-body">
                    <p class="card-text">
                      <router-link
                        to="/listing-city-category/halal-restaurants/Calgary"
                        class="nav-link"
                        >Calgary</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-city-category/halal-restaurants/Edmonton"
                        class="nav-link"
                        >Edmonton</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-city-category/halal-restaurants/Ajax"
                        class="nav-link"
                        >Ajax</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link
                        to="/listing-city-category/halal-restaurants/Brampton"
                        class="nav-link"
                        >Brampton</router-link
                      >
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="footercards">
                  <h4 class="card-title">Quick Links</h4>
                  <div class="card-body">
                    <p class="card-text">
                      <router-link to="/tips-articles"> Blog </router-link>
                    </p>
                    <p class="card-text">
                      <router-link to="/listing-category/halal-restaurants"
                        >Restaurants</router-link
                      >
                    </p>
                    <!-- <p class="card-text">Add your listings</p> -->
                    <p class="card-text">
                      <router-link to="/listing-category/halal-restaurants"
                        >Search On Map</router-link
                      >
                    </p>
                    <p class="card-text">
                      <router-link to="/contact-us">Contact Us</router-link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="footercards">
              <h4 class="card-title">SUBSCRIBE NEWSLETTER</h4>
              <div class="card-body">
                <!-- <h4 class="card-title">All About Ramadan Halal
                                Meat Market 2024</h4> -->
                <p class="card-text newslettertext">
                  Exerci tation ullamcorper suscipit lobortis nisl aliquip ex ea
                  commodo
                </p>
              </div>
              <div class="news-letter" v-if="newsletterCheck">
                <input
                  type="text"
                  class="form-control"
                  placeholder="info@example.com"
                  v-model="news_email"
                />
                <button type="button" class="btn btn-danger">
                  <img
                    @click="newsLetterValidator"
                    src="../assets/Sendemail.png"
                    alt="email send"
                  />
                </button>
              </div>
              <div v-if="successMessage != ''" class="successDiv">
                {{ successMessage }}
              </div>
              <div v-if="errorMessage != ''" class="errorDiv">
                {{ errorMessage }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="col-lg-12 col-md-12 bg-dark">
    <div class="bottom-footer">
      <p class="text-center">Copyright © 2024 MyHalalRestaurants.com</p>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {};
  },
  data() {
    return {
      news_email: '',
      errorMessage: '',
      successMessage: '',
      newsletterCheck: true,
    };
  },
  mounted() {
    // document.addEventListener('click', this.handleClickOutside);
    // this.getCitiesDD();
  },
  methods: {
    newsLetterValidator() {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      if (regex.test(this.news_email)) {
        this.errorMessage = '';
        this.getCitiesDD();
      } else {
        // alert('Please enter valid email.');
        this.errorMessage = 'Please enter valid email.';
      }
    },
    async getCitiesDD() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(
          this.apiUrl + 'newlettersubscription/' + this.news_email,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );
        //const response = await fetch('/api/citylist?name=' + this.cityDDModel);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        //document.getElementById('searchCityDiv').style.display = 'block';
        // this.categoryCount = data;
        if (data['affectedRows'] == 1) {
          this.successMessage =
            'Thank you for joining our newsletter family! Your enthusiasm warms our hearts. Stay tuned for exciting updates';
          this.newsletterCheck = false;
        }
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
footer {
  background-color: #000;
  height: auto;
  padding-bottom: 50px;

  .footer-socialmedia img {
    padding-right: 5px;
  }

  .footercards {
    .card-text {
      font-weight: 300;
      margin: 0;
      cursor: pointer;
      padding-top: 15px;
    }
    .card-title {
      margin-top: 10px;
    }
    .newslettertext:after {
      border: none;
    }
  }

  .footercards p:after {
    display: block;
    content: '';
    width: 30px;
    border-bottom: solid 2px #fff;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
  }

  .footercards p:after {
    transform-origin: 100% 50%;
  }

  .footercards p:hover:after {
    transform: scaleX(1);
    transform-origin: 0% 50%;
  }

  .card-title::after {
    border-bottom: 3px solid #ff1613;
    content: '';
    display: block;
    width: 40px;
  }

  .news-letter {
    display: flex;
    width: 90%;
    height: 65px;
    margin-top: 30px;

    button {
      border-radius: 0px 6px 6px 0px;
    }

    input {
      border-radius: 6px 0px 0px 6px;
      border: none;
      background-color: #3c3c3c;
      color: #fff;
    }

    text:focus,
    input:focus {
      color: #fff;
    }

    ::placeholder {
      color: #fff;
    }
  }
}

.bottom-footer {
  background-color: #1d1d1d;
  height: 40px;
  color: #ffffff;
  padding-top: 8px;
}

a {
  color: white;
  text-decoration: none;
}
.errorDiv {
  font-weight: 400;
  color: red;
  font-size: 14px;
}
.successDiv {
  font-weight: 300;
  color: greenyellow;
  font-size: 15px;
  margin-top: 20px;
  border: 1px solid greenyellow;
  padding: 15px;
  background-color: #adff2f25;
}

@media only screen and (min-device-width: 300px) and (max-device-width: 397px) {
  .bottom-footer {
    font-size: 12px;
  }
}
</style>
