<template>
  <div class="howsection">
    <div class="howtext">
      <h2>how it works</h2>
      <p>
        Discover how MyHalalRestaurants can you help you find everything you
        want.
      </p>
    </div>

    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <div class="howcards">
            <img src="../../assets/looking.png" alt="looking" />
            <div class="card-body">
              <h4 class="card-title">What are you looking for?</h4>
              <p class="card-text">
                Search and filter hundreds of listings, read reviews, explore
                photos and find the perfect Halal spot
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="howcards">
            <img src="../../assets/location.png" alt="looking" />
            <div class="card-body">
              <h4 class="card-title">Select Location</h4>
              <p class="card-text">
                Search and filter hundreds of listings, read reviews, explore
                photos and find the perfect Halal spot
              </p>
            </div>
          </div>
        </div>

        <div class="col-lg-4">
          <div class="howcards">
            <img src="../../assets/places.png" alt="looking" />
            <div class="card-body">
              <h4 class="card-title">Explore amazing Places</h4>
              <p class="card-text">
                Search and filter hundreds of listings, read reviews, explore
                photos and find the perfect Halal spot
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.howsection {
  background-color: #000;
  height: auto;
  padding-bottom: 50px;

  .howtext {
    padding-top: 50px;

    h2,
    p {
      color: #ff1613;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      font-style: medium;
      text-align: center;
      margin-top: 10px;
    }

    p {
      color: #fff;
      font-weight: 200;
    }
  }

  .howcards {
    position: relative;
    background-color: rgba(72, 72, 72, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    padding: 30px;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    maring-bottom: 10px;
  }
  .card-text {
    font-weight: 200;
    font-size: 14px;
    margin-top: 10px;
  }
}
</style>
