<template>
  <div>
    <BaseHeader />
    <div class="homeSlider">
      &nbsp;
      <div class="col-lg-12">
        <div class="home-text onMobile">
          <h1 class="desktopOnly">How It Works</h1>
        </div>
      </div>
    </div>
    <div class="about">
      <div class="about-container">
        <p class="centerAlign">
          Add your Halal Restaurant to MyHalalRestaurants.com, so customers can
          easily find it.
        </p>
        <p class="intro">
          My Halal Restaurants provides you with a smart set of tools to
          showcase your
          <a href="https://myhalalrestaurants.com/">Halal Restaurant</a>,
          Grocery Store, Meat Shop etc and connect to your community. Beautiful,
          simple and easy to use, My Halal Restaurant is a fun and friendly
          place to hook up with your customers.
        </p>
        <table>
          <tr>
            <td>
              <img class="count" src="@/assets/one.png" />
            </td>
            <td>
              <h4>Add or Claim your Listing</h4>
              <p>
                Upload photos, add helpful links to your website or to social
                media, set an address and hours of operation and other
                information that your customers may find relevant.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img class="count" src="@/assets/two.png" />
            </td>
            <td>
              <h4>Get Discovered by Visitors</h4>
              <p>
                Upon confirmation, your listing will appear throughout the
                website and will be searchable by visitors interested in various
                food categories. A badge will be added to your listing to mark
                it as official.
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <img class="count" src="@/assets/three.png" />
            </td>
            <td>
              <h4>Increase your earnings</h4>
              <p>
                Once discovered, customers will start coming to you! It’s your
                job from here to make them happy to leave positive reviews.
                That’s how My Halal Restaurants helps them discover Halal
                Restaurants & hidden halal gems in Canada.
              </p>
            </td>
          </tr>
        </table>
      </div>
      <div class="row">
        <div class="col-lg-1"></div>
        <div class="col-lg-10">
          <div></div>
        </div>
        <div class="col-lg-1"></div>
      </div>
    </div>
    <BaseFooter />
  </div>
</template>

<script>
import BaseHeader from '@/components/BaseHeader.vue';
import BaseFooter from '@/components/BaseFooter.vue';

export default {
  name: 'ContactUs',
  components: {
    BaseHeader,
    BaseFooter,
  },
  data() {
    return {
      name: '',
      email: '',
      subject: '',
      nameError: '',
      emailError: '',
      subjectError: '',
      contactFormCheck: false,
      successMessage: 'Thanks',
    };
  },
  mounted() {},
  methods: {
    submitContactUs() {
      if (this.name == '') {
        this.nameError = 'Name is required.';
      } else {
        this.nameError = '';
      }
      if (this.email == '') {
        this.emailError = 'Email is required.';
      } else {
        // this.emailError = '';
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (regex.test(this.email)) {
          this.emailError = '';
        } else {
          //alert('Please enter valid email.');
          this.emailError = 'Please enter valid email.';
        }
      }
      if (this.subject == '') {
        this.subjectError = 'Subject is required.';
      } else {
        this.subjectError = '';
      }
      if (this.name != '' && this.email != '' && this.subject != '') {
        this.getLatestListings();
      }
    },
    async getLatestListings() {
      try {
        const response = await fetch(this.apiUrl + 'contact_form', {
          method: 'POST',
          // headers: {
          //   // 'Content-Type': 'application/json',
          //   name: this.name,
          //   email: this.email,
          //   subject: this.subject,
          //   message: this.message,
          // },
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            name: this.name,
            email: this.email,
            subject: this.subject,
            message: this.message,
          }),
        });
        //const response = await fetch('/api/citylist?name=' + this.cityDDModel);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log(data['results']['affectedRows']);
        if (data['results']['affectedRows'] == 1) {
          this.successMessage =
            'Thank you for contacting us. We will back to you soon!';
          this.contactFormCheck = true;
        }
        // console.log(data, 'zain');
        // document.getElementById('searchCityDiv').style.display = 'block';
        // this.citiesff = data;
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.homeSlider {
  background-image: url('@/assets/howit.png');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 260px;
}
.about {
  background-color: black;
  .about-container {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 18px;
    font-weight: 300;
    table {
      width: 600px;
      margin: 0 auto;
      tr td {
        padding: 10px;
      }
      p {
        margin-left: 20px;
        font-size: 16px;
      }
    }
    .count {
      width: 100px;
    }
    .centerAlign {
      text-align: center;
      font-weight: 300;
      font-size: 24px;
    }
    h4 {
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      font-style: normal;
      margin-left: 20px;
    }
    p {
      font-family: 'Roboto', sans-serif;
      font-weight: 100;
      font-style: normal;
      a {
        color: #dc3545;
        font-weight: 500;
      }
    }
    .intro {
      text-align: center;
    }
  }
}
.home-text {
  text-align: center;
  margin-top: 100px;
}

.home-text h1 {
  font-size: 45px;
  color: #fff;
  font-family: 'Anton', sans-serif;
  font-weight: 400;
  font-style: normal;
  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666,
    0 5px 0 #555, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  .homeSlider {
    height: 600px;
  }

  .home-text h1 {
    font-size: 70px;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .home-text h1 {
    font-size: 70px;
  }
}
</style>
