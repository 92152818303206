<script>
import { defineComponent } from 'vue';
import { useRoute } from 'vue-router';
// import { useSearchFilterStore } from '@/stores/searchFilter';

export default defineComponent({
  setup() {
    const route = useRoute();
    const category = route.params.slug;
    const city = route.params.city;
    return {
      category,
      city,
    };
  },
  data() {
    return {
      cityName: this.$route.params._regions,
      latestListings: [{ id: 1, title: 'My journey with Vue' }],
    };
  },
  mounted() {
    this.getLatestListings();
  },
  methods: {
    stringToArray(string) {
      console.log(typeof string);
      if (typeof string == 'string') {
        return string.split(',');
      } else {
        return string;
      }
    },
    async getLatestListings() {
      let url = '';
      // var that = this;
      if (this.city != null) {
        url =
          'https://planetautopart.com/server/app/api/getlistingagainstsearch/' +
          this.category +
          '/' +
          this.city;
      } else {
        url =
          'https://planetautopart.com/server/app/api/listingformcatgoryname/' +
          this.category;
      }
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(url, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.latestListings = data;
        // console.log(, 'zzzz');

        this.error = null;
      } catch (error) {
        //console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data';
      } finally {
        //const loadingOverlay = document.getElementById('loading');
        //loadingOverlay.style.display = 'none';
      }
    },
  },
});
</script>
<template>
  <div class="listingsection">
    <div class="listingtext"></div>
    <div class="filterDiv">
      <form action="details.vue">
        <input type="checkbox" name="takeout" id="takeout" />
        <label for="takeout">&nbsp; Takeout</label>&nbsp;&nbsp;
        <input type="checkbox" name="takeout" id="takeout" />
        <label for="takeout">&nbsp; Dine-in</label>&nbsp;&nbsp;
        <input type="checkbox" name="takeout" id="takeout" />
        <label for="takeout">&nbsp; Pickup</label>&nbsp;&nbsp;
        <input type="checkbox" name="takeout" id="takeout" />
        <label for="takeout">&nbsp; Drive-Thru</label>&nbsp;&nbsp;
        <input type="checkbox" name="takeout" id="takeout" />
        <label for="takeout">&nbsp; Free Wifi</label>&nbsp;&nbsp;
        <!-- <select name="" id="">
          <option value="">Default</option>
          <option value="">Sort by Popularity</option>
          <option value="">Sort by Average Rating</option>
          <option value="">Sort by Highest Rating</option>
          <option value="">Sort by Latest</option>
        </select> -->
      </form>
    </div>
    <div class="container">
      <div
        class="row singleListing"
        v-for="(list, index) in latestListings"
        :key="index"
      >
        <div class="col-lg-5 col-md-5 bg-dark">
          <div class="listingcards" v-if="list.img">
            <router-link :to="'/listing/' + list.slug + '/'" class="nav-link">
              <img :src="list.img" :alt="list.title" />
            </router-link>
          </div>
          <div class="listingcards" v-else>
            <router-link :to="'/listing/' + list.slug + '/'" class="nav-link">
              <img src="@/assets/nop.png" :alt="list.title" />
            </router-link>
          </div>
        </div>

        <div class="col-lg-7 bg-dark">
          <div class="card-body">
            <h4 class="card-title">{{ list.title }}</h4>
            <p class="card-text"></p>
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <img class="listing-icon" src="@/assets/CardCategory.png" />
            </div>
            <span class="listing-text">Restaurant</span>
          </div>
          <div class="listing-tags">
            <!-- <span class="listing-tag">Pakistani</span>
            <span class="listing-tag">BBQ</span>
            <span class="listing-tag">Dine-In</span>
            <span class="listing-tag">Take Away</span> -->
            <spn
              class="listing-tag"
              v-for="(catlist, index2) in stringToArray(list.categories)"
              :key="index2"
            >
              {{ catlist }}
            </spn>
          </div>
          <div class="input-group mb-2">
            <!-- <div class="input-group-prepend">
              
            </div> -->
            <span class="listing-location"
              ><img class="mapIcon" src="@/assets/map.png" alt="" />
              {{ list.address }}</span
            >
          </div>
          <hr class="listing-hr" />
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="lr">
                <img src="@/assets/5star.png" alt="" />
              </div>
            </div>
            <span class="listing-rating">{{ list.rating }}</span>
          </div>

          <div class="listing-hours">
            <span class="listing-text">Reviews ({{ list.review_count }})</span>
          </div>
        </div>
      </div>
      <!-- <div class="col-lg-6 col-md-6 bg-dark">
          <div class="listingcards">
            <img src="../static/Lahore.png" alt="shisha" />
          </div>
        </div>

        <div class="col-lg-6 bg-dark">
          <div class="card-body">
            <h4 class="card-title">Super Hakka Restaurant</h4>
            <p class="card-text"></p>
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <img class="listing-icon" src="/static/CardCategory.png" />
            </div>
            <span class="listing-text">Restaurant</span>
          </div>
          <div class="listing-tags">
            <span class="listing-tag">Pakistani</span>
            <span class="listing-tag">BBQ</span>
            <span class="listing-tag">Dine-In</span>
            <span class="listing-tag">Take Away</span>
          </div>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <img src="../static/map.png" alt="" />
            </div>
            <span class="listing-location"
              >2683, Lawrence Avenue East, ON CA</span
            >
          </div>
          <hr class="listing-hr" />
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="lr">
                <img src="../static/star.png" alt="" />
              </div>
            </div>
            <span class="listing-rating">4.9</span>
          </div>

          <div class="listing-hours">
            <span class="listing-text" style="color: greenyellow"
              >Open Now</span
            >
          </div>
        </div>

        <div class="col-lg-6 col-md-6 bg-dark">
          <div class="listingcards">
            <img src="../static/Lahore.png" alt="shisha" />
          </div>
        </div>

        <div class="col-lg-6 bg-dark">
          <div class="card-body">
            <h4 class="card-title">Super Hakka Restaurant</h4>
            <p class="card-text"></p>
          </div>
          <div class="input-group mb-4">
            <div class="input-group-prepend">
              <img class="listing-icon" src="/static/CardCategory.png" />
            </div>
            <span class="listing-text">Restaurant</span>
          </div>
          <div class="listing-tags">
            <span class="listing-tag">Pakistani</span>
            <span class="listing-tag">BBQ</span>
            <span class="listing-tag">Dine-In</span>
            <span class="listing-tag">Take Away</span>
          </div>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <img src="../static/map.png" alt="" />
            </div>
            <span class="listing-location"
              >2683, Lawrence Avenue East, ON CA</span
            >
          </div>
          <hr class="listing-hr" />
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <div class="lr">
                <img src="../static/star.png" alt="" />
              </div>
            </div>
            <span class="listing-rating">4.9</span>
          </div>

          <div class="listing-hours">
            <span class="listing-text">Closed Now</span>
          </div>
        </div> -->
      <!-- 
                <div class="col-lg-12 col-md-6 bg-dark">
                    <div class="listingcards">
                        <img src="../static/Rolls.png" alt="rolls">
                        <div class="card-body">
                            <h4 class="card-title">Lett’us Roll</h4>
                            <p class="card-text"></p>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img class="listing-icon" src="/static/CardCategory.png" />
                            </div>
                            <span class="listing-text">Restaurant</span>
                        </div>
                        <div class="listing-tags">
                            <span class="listing-tag">Pakistani</span>
                            <span class="listing-tag">BBQ</span>
                            <span class="listing-tag">Dine-In</span>
                            <span class="listing-tag">Take Away</span>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img src="../static/map.png" alt="">
                            </div>
                            <span class="listing-location">2683, Lawrence Avenue
                                East, ON CA</span>
                        </div>
                        <hr class="listing-hr">
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="lr">
                                    <img src="../static/star.png" alt="">
                                </div>
                            </div>
                            <span class="listing-rating">5.0</span>
                        </div>

                        <div class="listing-hours">
                            <span class="listing-text" style="color: #00C008;">Open Now</span>
                        </div>
                    </div>

                </div> -->
      <!-- 
                <div class="col-lg-12 col-md-6 bg-dark">
                    <div class="listingcards">
                        <img src="../static/Sweets.png" alt="sweets">
                        <div class="card-body">
                            <h4 class="card-title">Al-Karam Sweets</h4>
                            <p class="card-text"></p>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img class="listing-icon" src="/static/CardCategory.png" />
                            </div>
                            <span class="listing-text">Bakery</span>
                        </div>
                        <div class="listing-tags">
                            <span class="listing-tag">Pakistani</span>
                            <span class="listing-tag">BBQ</span>
                            <span class="listing-tag">Dine-In</span>
                            <span class="listing-tag">Take Away</span>
                        </div>
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <img src="../static/map.png" alt="">
                            </div>
                            <span class="listing-location">2683, Lawrence Avenue
                                East, ON CA</span>
                        </div>
                        <hr class="listing-hr">
                        <div class="input-group mb-2">
                            <div class="input-group-prepend">
                                <div class="lr">
                                    <img src="../static/star.png" alt="">
                                </div>
                            </div>
                            <span class="listing-rating">4.7</span>
                        </div>
                        <div class="listing-hours">
                            <span class="listing-text">Closed Now</span>
                        </div>
                    </div>
                </div> -->
    </div>
  </div>
</template>
<style>
h4,
.h4 {
  font-size: 21px !important;
}
.mapIcon {
  width: 18px;
}
</style>
<style scoped lang="scss">
.singleListing {
  margin-top: 10px;
}
.listingsection {
  background-color: #000;
  height: auto;
  padding-bottom: 50px;
  margin-top: 30px;
  height: 83vh;
  overflow: hidden;
  overflow-y: scroll;
  margin-top: 15vh;

  .listingcards {
    // margin-top: 5px;
    display: flex;
    position: relative;
    background: rgba(72, 72, 72, 0.5);
    border-radius: 6px;
    padding: 10px;
    font-family: 'Roboto', sans-serif;

    img {
      width: 100%;
      object-fit: cover;
      // margin-bottom: 30px;
    }
    h4,
    .h4 {
      font-size: 15px !important;
    }
    .card-title {
      text-align: left;
    }
  }

  .listing-icon {
    width: 80%;
    margin-top: 5px;
  }

  .listing-text {
    margin-top: 4px;
    padding-left: 10px;
  }

  .listing-tags {
    margin-top: -20px;
    margin-bottom: 20px;
  }

  .listing-tag {
    padding: 5px;
    border: solid 1px #ff1613;
    border-radius: 10px;
    font-size: 12px;
  }

  .listing-location {
    padding-left: 10px;
    margin-top: 2px;
    font-size: 13px;
  }

  .listing-hr {
    margin-top: 5px;
  }

  .listing-rating {
    // margin-top: -3px;
    padding-left: 10px;
  }

  .listing-hours {
    float: right;
    margin-top: -35px;
    color: #ff1613;
  }

  @media only screen and (min-device-width: 768px) and (max-device-width: 1199px) {
    .listing-location {
      margin-top: -55px;
      padding-left: 30px;
    }
  }

  @media only screen and (min-device-width: 300px) and (max-device-width: 397px) {
    .listing-location {
      margin-top: -55px;
      padding-left: 30px;
    }
  }
}
</style>
