<template>
  <div class="categoryicon">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-1 col-sm-1"></div>
        <div class="col-lg-6 col-md-10 col-sm-10">
          <div class="row headercategories">
            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Asian/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/Asian.png"
                    class="card-img-top"
                    alt="asiancategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Asian</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Burgers/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/burgers.png"
                    class="card-img-top"
                    alt="burgerscategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Burgers</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Pizza/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/pizza.png"
                    class="card-img-top"
                    alt="pizzacategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Pizza</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Wings/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/wings.png"
                    class="card-img-top"
                    alt="wingscategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Wings</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Kebabs/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/kebabs.png"
                    class="card-img-top"
                    alt="kebabscategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Kebabs</p>
                  </div>
                </div>
              </router-link>
            </div>

            <div class="col-lg-2 col-md-2 col-sm-4">
              <router-link to="/listing-category/Kebabs/" class="nav-link">
                <div class="headercategory">
                  <img
                    src="../../assets/hakka.png"
                    class="card-img-top"
                    alt="hakkacategory"
                  />
                  <div class="card-body">
                    <p class="card-text">Hakka</p>
                  </div>
                </div>
              </router-link>
            </div>
          </div>
        </div>
        <div class="col-lg-3 col-md-1 col-sm-1"></div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.card-text {
  font-weight: 200;
  font-size: 12px;
  padding-top: 5px;
}
.headercategories {
  margin-top: -150px;
}
.headercategory {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 10px;
  text-align: center;
  border: solid 1px #ff1613;
  filter: drop-shadow(0 0 0.35rem #ff1613);
}
.headercategory:hover {
  transition-duration: 0.3s;
  filter: drop-shadow(0 0 1.25rem #ff1613);
}
@media screen and (max-width: 768px) {
  .headercategories {
    width: 300px;
    margin: 0 auto;
    margin-top: -250px;
    justify-content: center;
    .col-sm-4 {
      width: auto;
      padding-left: 3px;
      padding-right: 3px;
    }
  }
  .headercategory {
    margin-bottom: 10px;
    width: auto;
  }
  .categoryicon {
    .container {
      padding: 0px;
    }
  }
}
</style>
