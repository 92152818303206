<template>
  <div class="homeSlider">
    &nbsp;
    <div class="col-lg-12">
      <div class="home-text onMobile">
        <h1 class="desktopOnly">My Halal Restaurants</h1>
        <h1 class="mobileOnly">
          My Halal<br />
          Restaurants
        </h1>
        <p>Find great Halal places to eat & shop in Canada!</p>
      </div>
    </div>
  </div>
</template>
<!-- <div v-for="(list, index) in latestListings" :key="index">
    <nuxt-link :to="'/listing/' + list.slug + '/'" class="nav-link">
    </nuxt-link>
    <h4 style="color: #000;">{{ list.city }}</h4>
  </div> -->

<!-- <script>
export default {
  name: 'index',
  data() {
    return {
      listingSlug: this.$route.params.slug,
      latestListings: [{ id: 1, title: 'My journey with Vue'}],
    };
  },
  mounted() {
    this.getLatestListings();
  },
  methods: {
    async getLatestListings() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(
          '/api/getlatestlistings?name=' + this.listingSlug
        );
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        this.latestListings = data['data'];
        console.log(data['data'], 'zain');
        this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        this.error = 'An error occurred while fetching data';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script> -->

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Montez&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

.homeSlider {
  background-image: url('@/assets/homeSliderBG.jpg');
  /* Replace with your image path */
  background-size: cover;
  background-position: center;
  height: 500px;
}

.home-text {
  text-align: center;
  margin-top: 100px;
}

.home-text h1 {
  font-size: 80px;
  color: #fff;
  font-family: 'Montez', cursive;
  font-weight: 400;
  font-style: normal;
  text-shadow: 0 1px 0 #999, 0 2px 0 #888, 0 3px 0 #777, 0 4px 0 #666,
    0 5px 0 #555, 0 6px 1px rgba(0, 0, 0, 0.1), 0 0 5px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.3), 0 3px 5px rgba(0, 0, 0, 0.2),
    0 5px 10px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.2),
    0 20px 20px rgba(0, 0, 0, 0.15);
}

.desktopOnly {
  display: block;
}

.mobileOnly {
  display: none;
}

@media screen and (max-width: 768px) {
  .homeSlider {
    height: 600px;
  }

  .home-text h1 {
    font-size: 70px;
  }

  .desktopOnly {
    display: none;
  }

  .mobileOnly {
    display: block;
  }
}

@media screen and (max-width: 520px) {
  .home-text h1 {
    font-size: 70px;
  }
}
</style>
