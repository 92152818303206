<template>
  <div class="categorysection">
    <div class="categorytext">
      <h2>Top Categories</h2>
      <p>
        Discover how MyHalalRestaurants can you help you find everything you
        want.
      </p>
    </div>

    <div class="container">
      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/shisha.png" alt="shisha" />
            <div class="card-body">
              <h4 class="card-title">Shisha</h4>
              <p class="card-text">({{ categoryCount[0] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/bakery.png" alt="bakery" />
            <div class="card-body">
              <h4 class="card-title">Bakery</h4>
              <p class="card-text">({{ categoryCount[1] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/meatshop.png" alt="meat shop" />
            <div class="card-body">
              <h4 class="card-title">Meat Shop</h4>
              <p class="card-text">({{ categoryCount[2] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/dessert.png" alt="dessert" />
            <div class="card-body">
              <h4 class="card-title">Desserts</h4>
              <p class="card-text">({{ categoryCount[3] }})</p>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/catering.png" alt="catering" />
            <div class="card-body">
              <h4 class="card-title">Catering</h4>
              <p class="card-text">({{ categoryCount[4] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/tiffin.png" alt="tiffin" />
            <div class="card-body">
              <h4 class="card-title">Tiffin</h4>
              <p class="card-text">({{ categoryCount[5] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/foodtruck.png" alt="food truck" />
            <div class="card-body">
              <h4 class="card-title">Food Truck</h4>
              <p class="card-text">({{ categoryCount[6] }})</p>
            </div>
          </div>
        </div>

        <div class="col-lg-3 col-md-6">
          <div class="categorycards">
            <img src="@/assets/hall.png" alt="hall" />
            <div class="card-body">
              <h4 class="card-title">Banquet Hall</h4>
              <p class="card-text">({{ categoryCount[7] }})</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  setup() {
    return {
      //store: useSearchFilterStore(),
    };
  },
  data() {
    return {
      categoryCount: [111, 222, 333, 444, 555, 666, 777, 888],
    };
  },
  mounted() {
    // document.addEventListener('click', this.handleClickOutside);
    this.getCitiesDD();
  },
  methods: {
    async getCitiesDD() {
      //alert(this.categoryDDModel);
      try {
        const response = await fetch(this.apiUrl + 'categorycoount', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });
        //const response = await fetch('/api/citylist?name=' + this.cityDDModel);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        //document.getElementById('searchCityDiv').style.display = 'block';
        this.categoryCount = data;
        //this.error = null;
      } catch (error) {
        console.error('Error fetching data:', error);
        //this.error = 'An error occurred while fetching data';
      } finally {
        //this.isLoading = false;
      }
    },
  },
};
</script>
<style scoped lang="scss">
.categorysection {
  background: url(@/assets/topcategorybackground.png);
  background-color: #000;
  height: auto;
  padding-bottom: 50px;

  .categorytext {
    padding-top: 50px;

    h2,
    p {
      color: #ff1613;
      font-family: 'Roboto', sans-serif;
      font-weight: 400;
      text-transform: capitalize;
      font-style: medium;
      text-align: center;
    }

    p {
      color: #fff;
      font-weight: 200;
    }
  }
  .categorycards:hover {
    filter: drop-shadow(0 0 3rem #ff1613cc);
    transition-duration: 0.6s;
  }
  .categorycards {
    position: relative;
    background-color: #000000;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    text-align: center;
    color: #fff;
    padding: 30px;
    margin-top: 30px;
    font-family: 'Roboto', sans-serif;
    filter: drop-shadow(0 0 0.75rem #ff161366);
    .card-text {
      font-size: 20px;
      text-decoration: none;
      padding: 0px;
      margin-top: 0px;
    }
    h4 {
      font-weight: 300;
    }
    p {
      text-decoration: underline;
      color: #ff1613;
      margin-top: 20px;
      font-weight: 400;
      font-size: 16px !important;
      padding: 0px;
    }

    img {
      width: 100px;
      height: 100px;
      object-fit: cover;
      border-radius: 50%;
      margin-bottom: 30px;
    }
  }
}
</style>
